/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const H2 = styled.h2`
  ${tw`
    text-left
    text-2xl
    leading-9
    tracking-tight
    font-extrabold
    text-gray-900
    sm:text-3xl
    sm:leading-10
    capitalize
  `}
`
export default H2
