import gql from 'graphql-tag'

/*
  Push a product to the cart and get the udpated cart

  @query_variables: {"id": "PRODUCT_ID"}

  Example: 
    
      """
      {
        "data": {
          "addProductToCart": {
            "total": 825,
            "order_items": [
              {
                "product": {
                  "id": "muesli_001",
                  "title": "Muesli aux Fruit des Bois",
                  "sku": "muesli_001",
                  "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
                  "price": 825,
                  "vat": 550,
                  "thumbnail_url": "https://images.prismic.io/cryptruserui/85ff5fc2-c62d-4658-9a63-163f3bf46499_muesli_fruit_des_bois.jpeg?auto=compress,format",
                  "category_id": "brunch_base",
                  "__typename": "Product"
                },
                "quantity": 1,
                "total": 825,
                "__typename": "OrderItem"
              }
            ],
            "__typename": "Cart"
          }
        }
      }

      Query variables : {"id": "muesli_001"}
      """
*/
export const ADD_TO_CART_MUTATION = gql`
  mutation CartMutation($sku: String!) {
    addProductToCart(sku: $sku) @client {
      total
      order_items {
        quantity
        product {
          id
          title
          sku
          description
          price
          vat
          thumbnail_url
          category_id
        }
      }
    }
  }
`

export const REMOVE_TO_CART_MUTATION = gql`
  mutation CartMutation($sku: String!) {
    removeProductToCart(sku: $sku) @client {
      total
      order_items {
        quantity
        product {
          id
          title
          sku
          description
          price
          vat
          thumbnail_url
          category_id
        }
      }
    }
  }
`
