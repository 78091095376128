/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const Section = styled.section`
  ${tw`
    relative
    pt-24
    pb-20
  `}
  div {
    ${tw`
      relative
      max-w-7xl
    `}
  }
`

export default Section
