import Numeral from 'numeral'
import 'numeral/locales/fr'

Numeral.locale('fr')

export const toCurrency = (amount) =>
  amount === undefined ? '0,00€' : Numeral(amount / 100).format('0,0.00$')

// export const toPercentage = value =>
//   value === undefined ? '0.00%' : Numeral(value / 10000).format('0,0.00%')
