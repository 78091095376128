import React, { FunctionComponent } from 'react'
import { useMutation } from '@apollo/react-hooks'

import { REMOVE_TO_CART_MUTATION } from '../../../graphql/mutations'

// import { Cart, OrderItem } from '../../types'

// interface CartQueryInterface {
//   data: Cart
// }
interface State {
  productSku: string
}
const PlusBtn: FunctionComponent<State> = ({ productSku }) => {
  // const { data } = useQuery<CartQueryInterface>(CART_QUERY)
  const [removeItemToCart] = useMutation(REMOVE_TO_CART_MUTATION, {
    variables: { sku: productSku },
  })
  // muesli_001
  return (
    <button onClick={removeItemToCart} className="product-minus">
      -
    </button>
  )
}

export default PlusBtn
