/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

export const BtnPrimary = styled.button`
  ${tw`
    items-center
    px-4
    py-1
    text-xs
    leading-6
    font-medium
    rounded-lg
    text-white
    bg-gray-900
    hover:bg-gray-800
    focus:outline-none
    focus:border-gray-900
    focus:shadow-outline
    active:bg-gray-900
    transition
    ease-in-out
    duration-150
  `}
`

export const BtnSecondary = styled.button`
  ${tw`
    items-center
    px-4
    py-1
    text-xs
    leading-6
    font-medium
    rounded-lg
    text-gray-900
    bg-gray-200
    hover:bg-gray-100
    focus:outline-none
    focus:border-gray-200
    focus:shadow-outline
    active:bg-gray-200
    transition
    ease-in-out
    duration-150
  `}
`

export const QuantityBtn = styled.div`
  ${tw`
    inline-flex
    bg-gray-300
    rounded-lg
    text-xs
    text-gray-600
    leading-6
  `}
  button {
    ${tw`
      py-1
      px-2
      md:px-3
      hover:bg-gray-200
      focus:outline-none
      focus:border-gray-300
      focus:shadow-outline
      active:bg-gray-300
      transition
      ease-in-out
      duration-150
    `}
  }
  .product-minus {
    ${tw`
      rounded-r-lg
    `}
  }
  .product-plus {
    ${tw`
      rounded-l-lg
    `}
  }
  p {
    ${tw`
      py-1
      px-2
      md:px-3
      font-semibold
      bg-gray-100
    `}
  }
`
